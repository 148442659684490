export const CONSTANTE1 = -57.372
export const CONSTANTE2 = -66.479
export const CONSTANTE3 = -44.233
export const CONSTANTE4 = -60.93

export const TREINO_FREQUENCIA = [
  { label: 'Si', value: 'sim' },
  { label: 'No', value: 'nao' },
]

export const TREINO_VEZES_SEMANA = [
  /* { label: 'Não treina', value: 'nao' }, */
  { label: '1 a 2 veces', value: '1-2' },
  { label: '3 a 4 veces', value: '3-4' },
  { label: '5 o más veces a la semana', value: '5-mais' },
]

export const TREINO_DISTANCIA_MEDIA = [
  { label: 'hasta 5 km', value: '0-5' },
  { label: 'De 6 a 10 km', value: '6-10' },
  { label: 'De 11 a 15 km', value: '11-15' },
  { label: 'De 16 a 21 km', value: '16-21' },
  { label: 'De 22 a 42 km', value: '22-42' },
  { label: 'Más de 42 km', value: '42-mais' },
]

export const INITIAL_DATA_CORREDOR = [
  {
    slug: 'escapar_tensao',
    peso: 0,
    atributos: [2.094, 1.573, 1.448, 0.641],
  },
  {
    slug: 'perder_peso',
    peso: 0,
    atributos: [2.764, 3.046, 2.58, 3.807],
  },
  { slug: 'ter_tempo', peso: 0, atributos: [0.553, 0.091, -0.212, -0.989] },
  {
    slug: 'tempo_pensar',
    peso: 0,
    atributos: [2.234, 1.724, 1.045, 0.633],
  },
  // eslint-disable-next-line prettier/prettier
  { slug: 'manter_saude', peso: 0, atributos: [5.139, 4.182, 5.12, 5.475] },
  {
    slug: 'melhorar_corpo',
    peso: 0,
    atributos: [2.423, 2.261, 1.961, 3.131],
  },
  {
    slug: 'melhorar_performance',
    peso: 0,
    atributos: [-1.362, -0.483, -0.333, -0.302],
  },
  {
    slug: 'melhorar_performance_esportes',
    peso: 0,
    atributos: [0.228, 0.837, 0.828, 1.446],
  },
  {
    slug: 'rotina_treinos',
    peso: 0,
    atributos: [0.113, 1.439, 1.265, 1.429],
  },
  {
    slug: 'estilo_vida_saudavel',
    peso: 0,
    // eslint-disable-next-line prettier/prettier
    atributos: [3.4, 3.471, 2.652, 4.913],
  },
  {
    slug: 'melhor_desempenho_trabalho',
    peso: 0,
    // eslint-disable-next-line prettier/prettier
    atributos: [1.956, 1.86, 1.281, 1.682],
  },
  // eslint-disable-next-line prettier/prettier
  { slug: 'socializar', peso: 0, atributos: [0.525, 1.392, 0.917, 0.6] },
  {
    slug: 'corredor_profissional',
    peso: 0,
    // eslint-disable-next-line prettier/prettier
    atributos: [1.7, 2.794, 1.674, 1.7],
  },
  // eslint-disable-next-line prettier/prettier
  { slug: 'manter_ativo', peso: 0, atributos: [7.827, 8.28, 4.692, 8.164] },
  {
    slug: 'opcao_lazer',
    peso: 0,
    // eslint-disable-next-line prettier/prettier
    atributos: [-0.781, -0.46, -0.48, -2.068],
  },
  {
    slug: 'correr_ganhar',
    peso: 0,
    atributos: [-0.163, 0.782, 0.431, 0.109],
  },
  {
    slug: 'amo_correr',
    peso: 0,
    atributos: [-0.714, -0.797, 0.182, -1.978],
  },
]

export const INITIAL_DATA_CORRIDA = [
  { slug: 'cenario_preferido', peso: 0 },
  { slug: 'areas_desgatadas', peso: 0 },
  { slug: 'preferencia_treino', peso: 0 },
  { slug: 'prioridade', peso: 0 },
  { slug: 'preferencia_viagem', peso: 0 },
  { slug: 'preferencia_atividade', peso: 0 },
  { slug: 'preferencia_corrida', peso: 0 },
]
