import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { ditoTrack } from '../../helpers/dito'
import ResetarQuiz from './resetar'
import QuizResultadoTipoCorrida from './resultadoTipoCorrida'
import data from '../../data/quizTotem'

const CalculaResultadoQuiz = ({ dadosQuiz}) => {
  const [pontuacaoTotalCorrida] = useState(
    dadosQuiz.dadosCorrida.map((item) => item.peso).reduce((a, b) => a + b, 0 - dadosQuiz.dadosCorrida[4].peso)
  )

  useEffect(() => {
    const ditoData = {
      pontuacao_total: pontuacaoTotalCorrida,
    }

    data.tipoCorrida.forEach((v, k) => {
      const alternativaSelecionada = v.alternativas.find(
        (alternativa) => alternativa.peso === dadosQuiz.dadosCorrida[k].peso
      )
      ditoData[v.slug] = alternativaSelecionada
    })

    ditoTrack('respondeu-quiz', ditoData)
  }, [])
  
  return (
    <>
    {pontuacaoTotalCorrida && (
      <QuizResultadoTipoCorrida
        pontuacaoTotalCorrida={pontuacaoTotalCorrida}
      />
    )}
    <ResetarQuiz shareParams={{ pontuacaoTotalCorrida }} />
  </>
  )
}

CalculaResultadoQuiz.propTypes = {
  dadosQuiz: PropTypes.object.isRequired,
}

export default CalculaResultadoQuiz
