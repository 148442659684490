import React from 'react'
import PropTypes from 'prop-types'
import { Trans, useI18next, useTranslation } from 'gatsby-plugin-react-i18next'
import { graphql } from 'gatsby'
import cx from 'classnames'
import CircularProgress from '@material-ui/core/CircularProgress'
import Button from '../Buttons/button'
import Label from '../Form/label'
import Input from '../Form/input'
import Select from '../Form/select'
import Option from '../Form/option'
import InputMask from '../Form/mask'
import Checkbox from '../Form/checkbox'
import Radio from '../Form/radio'
import ErrorMessage from '../ErrorMessage'
import {
  maskTelefone,
  maskTelefoneCL,
  maskTelefoneCO,
} from '../../helpers/util'

import * as S from './styles'

const MASK = {
  br: maskTelefone,
  co: maskTelefoneCO,
  cl: maskTelefoneCL,
}

const FormQuestionario = ({
  dadosFormQuiz,
  aceiteTermosUso,
  erroCampos,
  loading,
  handleSubmit,
  handleChangeForm,
  handleTermosUso,
}) => {
  const { t } = useTranslation()
  const { language } = useI18next()

  let form_data

  if (language === 'br') {
    form_data = require('../../constants/quiz')
  } else {
    form_data = require('../../constants/quiz_es')
  }

  return (
    <S.FormQuestionario onSubmit={handleSubmit}>
      <span className="wrapper">
        <div className="item-form">
          <Label texto="nome">
            <Trans>Nome</Trans>*
          </Label>

          <Input
            type="text"
            id="nome"
            placeholder={t('name_placeholder')}
            value={dadosFormQuiz.nome}
            onChange={handleChangeForm}
            className={erroCampos.nome && 'erro'}
          />
          {erroCampos.nome && <ErrorMessage mensagem={erroCampos.nome} />}
        </div>
        <div className="item-form">
          <Label texto="email">
            <Trans>e-mail</Trans>*
          </Label>
          <Input
            type="email"
            id="email"
            placeholder={t('email_placeholder')}
            value={dadosFormQuiz.email}
            onChange={handleChangeForm}
            className={erroCampos.email && 'erro'}
          />
          {erroCampos.email && <ErrorMessage mensagem={erroCampos.email} />}
        </div>
        <div className="item-form">
          <Label texto="telefone">
            <Trans>Telefone</Trans>*
          </Label>
          <InputMask
            type="tel"
            id="telefone"
            placeholder={t('phone_placeholder')}
            value={dadosFormQuiz.telefone}
            onChange={handleChangeForm}
            className={cx('mask-input', { erro: erroCampos.telefone })}
            mask={MASK[language]}
          />
          {erroCampos.telefone && (
            <ErrorMessage mensagem={erroCampos.telefone} />
          )}
        </div>
        <div className="item-form">
          <Label texto="genero">
            <Trans>Gênero</Trans>*
          </Label>
          <Select
            id="genero"
            value={dadosFormQuiz.genero}
            onChange={handleChangeForm}
            className={erroCampos.genero && 'erro'}
          >
            <Option value="">{t('genre_placeholder')}</Option>
            <Option value="feminino">{t('feminino')}</Option>
            <Option value="masculino">{t('masculino')}</Option>
            <Option value="nao-binario">{t('nao-binario')}</Option>
            <Option value="outro">{t('outro')}</Option>
            <Option value="nao-responder">{t('nao-responder')}</Option>
          </Select>
          {erroCampos.genero && <ErrorMessage mensagem={erroCampos.genero} />}
        </div>
      </span>
      <span className="wrapper">
        <div className="item-form">
          <Label texto="treino_frequencia">
            <Trans>Você treina com frequência?</Trans>
          </Label>
          <Select id="treino_frequencia" onChange={handleChangeForm}>
            <Option value="">{t('option_placeholder')}</Option>
            {form_data.TREINO_FREQUENCIA.map((val) => (
              <Option key={`frequencia-${val.label}`} value={val.value}>
                {val.label}
              </Option>
            ))}
          </Select>
        </div>
        <div className="item-form">
          <Label texto="treino_vezes_semana">
            <Trans>Quantas vezes por semana?</Trans>
          </Label>
          <Select id="treino_vezes_semana" onChange={handleChangeForm}>
            <Option value="">{t('option_placeholder')}</Option>
            {form_data.TREINO_VEZES_SEMANA.map((val) => (
              <Option key={`frequencia-${val.label}`} value={val.value}>
                {val.label}
              </Option>
            ))}
          </Select>
        </div>
        <div className="item-form">
          <Label texto="treino_distancia_media">
            <Trans>Qual a distância média percorrida?</Trans>
          </Label>
          <Select id="treino_distancia_media" onChange={handleChangeForm}>
            <Option value="">{t('option_placeholder')}</Option>
            {form_data.TREINO_DISTANCIA_MEDIA.map((val) => (
              <Option key={`frequencia-${val.label}`} value={val.value}>
                {val.label}
              </Option>
            ))}
          </Select>
        </div>
        <div className="item-form">
          <Label texto="treino_aplicativo">
            <Trans>
              Você usa algum app para acompanhar a evolução da sua corrida?
            </Trans>
          </Label>
          <span className="wrapper-radio">
            <Radio
              value="sim"
              name="treino_aplicativo"
              id="treino_aplicativo_sim"
              checked={dadosFormQuiz.treino_aplicativo === 'sim'}
              onChange={handleChangeForm}
            />
            <label htmlFor="treino_aplicativo_sim">
              <Trans>Sim</Trans>
            </label>
            <Radio
              value="nao"
              name="treino_aplicativo"
              id="treino_aplicativo_nao"
              checked={dadosFormQuiz.treino_aplicativo === 'nao'}
              onChange={handleChangeForm}
            />
            <label htmlFor="treino_aplicativo_nao">
              <Trans>Não</Trans>
            </label>
          </span>
        </div>
      </span>
      <span className="wrapper-botao">
        <Checkbox
          id="politica"
          name="aceiteTermosUso"
          value={aceiteTermosUso}
          checked={aceiteTermosUso === true}
          onChange={() => handleTermosUso(!aceiteTermosUso)}
        />
        <Button type="submit" disabled={!!(loading || !aceiteTermosUso)}>
          {loading ? (
            <>
              <CircularProgress size={25} className="circleLoading" />
              <Trans>Gerando Resultado</Trans>
            </>
          ) : (
            'Resultado'
          )}
        </Button>
      </span>
    </S.FormQuestionario>
  )
}

FormQuestionario.propTypes = {
  dadosFormQuiz: PropTypes.object.isRequired,
  aceiteTermosUso: PropTypes.bool.isRequired,
  erroCampos: PropTypes.object.isRequired,
  loading: PropTypes.bool.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  handleChangeForm: PropTypes.func.isRequired,
  handleTermosUso: PropTypes.func.isRequired,
  origem: PropTypes.string,
}

export default FormQuestionario

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`
