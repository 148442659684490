import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { useI18next, useTranslation } from 'gatsby-plugin-react-i18next'
import { navigate, graphql } from 'gatsby'
import Container from '@material-ui/core/Container'
import SEO from '../../../components/SEO'
import Layout from '../../../components/Layout'
import QuizResultado from '../../../components/Quiz/resultado'
import Loading from '../../../components/Loading'

import * as S from '../styles'

const QuizResultadoPage = ({ location }) => {
  const [loading, setLoading] = useState(true)
  const { language } = useI18next()
  const { t } = useTranslation()

  useEffect(() => {
    if (location.state === null || location.state === undefined) {
      navigate(`/${language}/quiz`)
    } else {
      setLoading(false)
    }
  }, [location.state, language])

  if (loading) {
    return <Loading />
  }

  return (
    <Layout>
      <SEO title={t('quiz_title_seo')} description={t('description_seo')} />
      <S.Bg isCorredor>
        <Container>
          <QuizResultado dadosQuiz={location.state} />
        </Container>
      </S.Bg>
    </Layout>
  )
}

QuizResultadoPage.propTypes = {
  location: PropTypes.object.isRequired,
}

export default QuizResultadoPage

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`
